<template>
  <div class="d-flex-column align-center main">
    <el-form label-position="top" class="main full-width input-underline">
      <el-card>
        <div class="mw-400 main">
          <div v-for="(field, key) in form" :key="key">
            <SuperInput
              v-model="field.value"
              :errors.sync="field.errors"
              :field="field"/>
          </div>
        </div>
      </el-card>
      <div class="d-flex-column main mw-400">
        <div class="align-text-center">
          <el-button type="primary" class="full-width" @click="submitForm">
            Register Interest
          </el-button>
        </div>
        <div class="align-text-center">
          <el-button class="full-width button-invisible" @click="back">Back</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SuperInput from '@/components/form/fields/SuperInput.vue';
import { makeForm, flatten, errorOrPass } from '@/utils/forms';
import { emailStrategy, mobileStrategy } from '@/utils/validators/strategy';
import msg from '@/utils/constants/msg';

export default {
  name: 'ExpressInterest',
  components: { SuperInput },
  mounted() {
    const { entityType, entityId } = this.$route.params;
    if (entityType && entityId) {
      this.entityType = entityType;
      this.entityId = entityId;
    }
  },
  data() {
    return {
      entityType: 'national',
      entityId: 1,
      form: makeForm({
        name: { label: 'Full Name', required: true },
        postcode: { label: 'Postcode', required: true },
        dob: {
          component: 'DateInput',
          label: 'Birthday',
          required: true,
        },
        email: {
          label: 'Email',
          validators: [emailStrategy],
          required: true,
        },
        mobile: {
          label: 'Mobile',
          validators: [mobileStrategy],
          required: true,
        },
        comments: { label: 'Any additional comments', required: false },
      }),
    };
  },
  methods: {
    ...mapMutations('views', ['PUSH_NOTIFICATION']),
    back() {
      this.$router.push({ name: 'home' }).catch(() => {});
    },
    submitForm() {
      // Trigger Form Validation
      if (!errorOrPass(this.form, [], this)) return;
      // Get Form Data
      const data = flatten(this.form);
      // Add Entity Fields
      data.entityType = this.entityType;
      data.entityId = this.entityId;
      // Save Expression of Interest
      this.$store.commit('root/LOADING', true);
      this.$httptoken
        .post('/nrl/api/v1/portal-public/registration/expression-interest', data)
        .then(() => {
          this.$store.commit('root/LOADING', false);
          this.$router.push({ name: 'contactreceived' });
        })
        .catch((error) => {
          this.PUSH_NOTIFICATION({
            msg: error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : msg.error.apiError,
            type: 'warning',
          });
          this.$store.commit('root/LOADING', false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0 !important;
}
</style>
